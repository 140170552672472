<div class="contact-form-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="contact-item">
                    <h3 class="form-heading">{{heading}}</h3>
                    <form id="contactForm" [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)">
                        <div class="row">
                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="hidden" formControlName="type" value={{type}}>
                                    <input type="text" formControlName="name" id="name" class="form-control"

                                        placeholder="Name">
                                    <form-error-component [controlName]="FormData.controls['name']">
                                    </form-error-component>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="email" formControlName="email" id="email"
                                        class="form-control"
                                        placeholder="Email">
                                    <form-error-component [controlName]="FormData.controls['email']">
                                    </form-error-component>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="text" formControlName="phone"

                                        class="form-control" placeholder="Phone">
                                    <form-error-component [controlName]="FormData.controls['phone']">
                                    </form-error-component>
                                </div>
                            </div>

                            <div class="col-sm-6 col-lg-12">
                                <div class="form-group">
                                    <input type="text" formControlName="subject"
                                        class="form-control" required
                                        placeholder="Subject">
                                    <form-error-component [controlName]="FormData.controls['subject']">
                                    </form-error-component>
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <div class="form-group">
                                    <textarea formControlName="message" class="form-control"
                                        cols="30" rows="6"
                                        required placeholder="Message"></textarea>
                                    <form-error-component [controlName]="FormData.controls['message']">
                                    </form-error-component>
                                </div>
                            </div>

                            <div class="col-md-12 col-lg-12">
                                <div class="alert alert-success" *ngIf="isSuccess" role="alert">
                                    <strong>Success!</strong> Thank you for contacting us.
                                </div>
                                <div class="alert alert-danger" *ngIf="isError" role="alert">
                                    <strong>Error!</strong> Something went wrong.
                                </div>
                                <button type="submit" [disabled]="!FormData.valid || isLoading" class="cmn-btn btn">
                                    <span *ngIf="isLoading">Sending...</span>
                                    <span *ngIf="!isLoading">Send Message</span>
                                </button>
                            </div>
                        </div>
                    </form>

                    <div class="contact-social">
                        <span>Follow Us on</span>

                        <ul>
                            <li><a href="https://www.facebook.com/mamledar" target="_blank"><i
                                        class='bx bxl-facebook'></i></a></li>
                            <li><a href="https://www.instagram.com/mamledarmisal.official" target="_blank"><i
                                        class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>