<div class="navbar-area fixed-top">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo.png" alt="Logo"></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home</a></li>

                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">About</a></li>

                        <li class="nav-item"><a routerLink="/menu" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Menu</a></li>

                        <li class="nav-item"><a routerLink="/outlets" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Outlets</a></li>

                        <li class="nav-item nav-tel nav-tel-link"><a routerLink="/franchise" class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}"><i class='bx bxs-store-alt'></i> Franchise</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Contact</a>
                        </li>
                    </ul>

                    <div class="side-nav">
                        <a class="nav-tel" href="#"><i class='bx bxs-cart'></i>Order Now</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>