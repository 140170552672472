import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactService } from './contact.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  FormData: FormGroup;
  isSuccess = false;
  isError = false;
  isLoading = false;

  @Input() heading = '';
  @Input() type?= 'contact';

  constructor(private builder: FormBuilder, private contact: ContactService) { }

  ngOnInit(): void {
    this.FormData = this.builder.group({
      name: new FormControl('', [Validators.required]),
      type: new FormControl(this.type, [Validators.required]),
      email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
      phone: new FormControl('', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
      subject: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
    })
  }

  onSubmit(FormData) {
    console.log(FormData)
    this.isLoading = true;
    this.isSuccess = false;
    this.isError = false;
    this.contact.PostMessage(FormData)
      .subscribe(response => {
        this.isSuccess = true;
        this.isLoading = false;
        const contactType = this.type;
        this.FormData.reset();
        setTimeout(() => {
          this.type = contactType;
          this.FormData.setValue({ type: contactType })
        }, 1000);
        console.log({ response })
      }, error => {
        this.isLoading = false;
        this.isError = true;
        console.warn(error.responseText)
        console.log({ error })
      })
  }

}
