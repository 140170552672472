<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area page-title-img-one">
    <!-- <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item">
                    <h2>Franchise</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li>Franchise</li>
                    </ul>
                </div>
            </div>
        </div>
    </div> -->
</div>


<div>
    <div class="container">
        <h3 class="text-center">What media say about us?</h3>
        <div class="row mt-4">
            <div class="col-sm-6 col-lg-4">
                <div class="chef-item">
                    <a target="_blank"
                        href="https://www.mid-day.com/mumbai-guide/mumbai-food/article/mumbai-food-popular-eatery-that-served-raj-thackeray-likely-to-expand-18708194"
                        class="chef-top">
                        <img src="assets/img/articles/1.png" alt="Chef">
                    </a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="chef-item">
                    <a target="_blank" href="https://bombayreport.com/mamledar-misal/" class="chef-top">
                        <img src="assets/img/articles/2.png" alt="Chef">
                    </a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="chef-item">
                    <a target="_blank" href="http://thaneliving.com/food/mamledar-misal-spicing-up-thanekars/"
                        class="chef-top">
                        <img src="assets/img/articles/3.png" alt="Chef">
                    </a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="chef-item">
                    <a target="_blank"
                        href="https://www.slurrp.com/article/the-10-best-spots-for-misal-pav-in-the-city-of-mumbai-1673086092057"
                        class="chef-top">
                        <img src="assets/img/articles/4.jpg" alt="Chef">
                    </a>
                </div>
            </div>

        </div>
    </div>
</div>


<app-footer-style-one></app-footer-style-one>