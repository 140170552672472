<app-navbar-style-one></app-navbar-style-one>

<div class="banner-area-three">
    <div class="banner-shape">
        <img src="assets/img/home-three/banner3.png" alt="Shape">
        <img src="assets/img/home-three/banner2.png" alt="Shape">
        <img src="assets/img/home-three/banner4.png" alt="Shape">
    </div>

    <div class="container">
        <div class="banner-content">
            <h1>Make Your Party With Delicious Dishes</h1>
            <p>A restaurant or an eatery, is a business that prepares and serves food and drinks to customers. Meals are generally served and eaten on the premises, but many.</p>
            <div class="banner-btn-wrap">
                <a class="cmn-btn" routerLink="/online-order">Order Now</a>
                <a class="banner-btn-two" routerLink="/contact">Get Free Call</a>
            </div>
        </div>

        <div class="banner-img">
            <img src="assets/img/home-three/banner1.jpg" alt="Banner">
        </div>
    </div>
</div>

<div class="about-area-two pt-100 pb-70">
    <div class="about-shape"><img src="assets/img/home-three/about3.png" alt="Shape"></div>

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/img/home-three/about1.jpg" alt="About">
                    <img src="assets/img/home-three/about2.png" alt="About">

                    <div class="video-wrap">
                        <a href="https://www.youtube.com/watch?v=aqz-KE-bpKQ" class="popup-youtube"><i class='bx bx-play'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-content">
                    <div class="section-title">
                        <h2>About Us</h2>
                        <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                        <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby.</p>
                    </div>
                    <a class="cmn-btn" routerLink="/services-details">Know More</a>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-area-three pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="services-item">
                    <div class="section-title">
                        <span class="sub-title">Sevices</span>
                        <h2>Mamledar Misal Services</h2>
                        <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                    </div>

                    <div class="row">
                        <div class="col-sm-6 col-lg-6">
                            <ul class="accordion">
                                <li>
                                    <a><span>01.</span>Fresh Healthy Food</a>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                </li>
                                <li>
                                    <a><span>02.</span>Quality Services</a>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                </li>
                                <li>
                                    <a><span>03.</span>Free Home Delivery</a>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                </li>
                            </ul>
                        </div>

                        <div class="col-sm-6 col-lg-6">
                            <ul class="accordion">
                                <li>
                                    <a><span>04.</span>Discount Voucher</a>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                </li>
                                <li>
                                    <a><span>05.</span>Party Center</a>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                </li>
                                <li>
                                    <a><span>06.</span>Our Convension</a>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="services-img">
                    <img src="assets/img/home-three/services1.png" alt="services-image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="collection-area collection-area-two ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Regular Food Collections</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="sorting-menu">
            <ul>
                <li class="filter active" data-filter="all">All</li>
                <li class="filter" data-filter=".web">Fast Food</li>
                <li class="filter" data-filter=".ui">Platters</li>
                <li class="filter" data-filter=".ux">Dessert</li>
                <li class="filter" data-filter=".branding">Asian Food</li>
            </ul>
        </div>

        <div id="Container" class="row">
            <div class="col-sm-6 col-lg-3 mix web ui">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/1.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>

                    <div class="collection-bottom">
                        <h3>Dark Chocolate Cake</h3>
                        <ul>
                            <li><span>$25</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1" />
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix ui">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/2.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Cake with Drinks</h3>
                        <ul>
                            <li><span>$15</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1" />
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix ux ui">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/3.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Doughnut Chocolate</h3>
                        <ul>
                            <li><span>$20</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1" />
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix branding web">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/4.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Dark Chocolate Cake</h3>
                        <ul>
                            <li><span>$23</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1" />
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix web branding">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/5.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Sweet Dougnuts</h3>
                        <ul>
                            <li><span>$35</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1" />
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix ui web">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/6.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Birthday Cake</h3>
                        <ul>
                            <li><span>$32</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1" />
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix ux branding">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/7.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Chocolate Ice Cream</h3>
                        <ul>
                            <li><span>$28</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1" />
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix branding ui">
                <div class="collection-item">
                    <div class="collection-top">
                        <img src="assets/img/home-one/collection/8.jpg" alt="Collection">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart">
                            <a routerLink="/cart"><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom">
                        <h3>Dark Chocolate Cake</h3>
                        <ul>
                            <li><span>$27</span></li>
                            <li>
                                <div class="number">
                                    <span class="minus">-</span>
                                    <input type="text" class="form-control" value="1" />
                                    <span class="plus">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="more-collection">
            <a routerLink="/food-collection">View More Colletction</a>
        </div>
    </div>
</section>

<section class="download-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="download-content">
                    <div class="section-title">
                        <span class="sub-title">Download</span>
                        <h2>Download Our Mobile App That Make You More Easy to Order</h2>
                        <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                    </div>

                    <ul>
                        <li><span>01</span> Select Your Food</li>
                        <li><span>02</span> Add to Cart</li>
                        <li><span>03</span> Order Your Food</li>
                    </ul>

                    <div class="app-wrap">
                        <a href="#"><img src="assets/img/home-two/google-store.png" alt="Google"></a>
                        <a href="#"><img src="assets/img/home-two/app-store.png" alt="App"></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="download-img">
                    <img src="assets/img/home-two/download1.png" alt="Download">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="reservation-area">
    <div class="reservation-shape"><img src="assets/img/home-one/reservation-shape.png" alt="Shape"></div>

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="reservation-item">
                    <div class="section-title">
                        <h2>Reservation A Table</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    </div>
                    <form>
                        <ul>
                            <li>
                                <input type="date" class="form-control" name="arrive">
                            </li>
                            <li>
                                <input type="time" class="form-control" name="arrive">
                            </li>
                            <li>
                                <button type="submit" class="btn cmn-btn">Reserve Now</button>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="reservation-img">
                    <img src="assets/img/home-one/reservation.png" alt="Reservation">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="chef-area chef-area-two pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Special Chefs</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="chef-item">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/1.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>John Doe</h3>
                            <span>Head of Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="chef-item">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/6.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>John Smith</h3>
                            <span>Assistant Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="chef-item active">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/3.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>Evanaa</h3>
                            <span>Intern Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="chef-item">
                    <div class="chef-top">
                        <img src="assets/img/home-one/chef/7.jpg" alt="Chef">
                        <div class="chef-inner">
                            <h3>Knot Doe</h3>
                            <span>Asst. Chef</span>
                        </div>
                    </div>
                    <div class="chef-bottom">
                        <ul>
                            <li><a target="_blank" href="#"><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank" href="#"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="review-area review-area-two">
    <div class="review-shape"><img src="assets/img/home-two/review2.png" alt="Review"></div>

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="review-img">
                    <img src="assets/img/home-two/review1.jpg" alt="Review">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="review-item">
                    <div class="section-title">
                        <h2>What People Say About Us</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="slider-nav">
                        <div class="item">
                            <img src="assets/img/home-one/review3.png" alt="image" draggable="false" />
                        </div>

                        <div class="item">
                            <img src="assets/img/home-one/review4.png" alt="image" draggable="false" />
                        </div>

                        <div class="item">
                            <img src="assets/img/home-one/review5.png" alt="image" draggable="false" />
                        </div>

                        <div class="item">
                            <img src="assets/img/home-one/review6.png" alt="image" draggable="false" />
                        </div>

                        <div class="item">
                            <img src="assets/img/home-one/review7.png" alt="image" draggable="false" />
                        </div>

                        <div class="item">
                            <img src="assets/img/home-one/review8.png" alt="image" draggable="false" />
                        </div>
                    </div>

                    <div class="slider-for">
                        <div class="item">
                            <h3>John Doe</h3>
                            <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                        </div>

                        <div class="item">
                            <h3>Jac Jackson</h3>
                            <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                        </div>

                        <div class="item">
                            <h3>Tom Henry</h3>
                            <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                        </div>

                        <div class="item">
                            <h3>John Mic</h3>
                            <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                        </div>

                        <div class="item">
                            <h3>Stark Arey</h3>
                            <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                        </div>

                        <div class="item">
                            <h3>Stark Arey</h3>
                            <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Regular Blogs</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-detailhtml">
                            <img src="assets/img/home-one/blog1.jpg" alt="Blog">
                        </a>
                        <span>01 May 2020</span>
                    </div>
                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-detailhtml">Brief About How to Make Pasta</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a class="cmn-btn" routerLink="/blog-detailhtml">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-detailhtml">
                            <img src="assets/img/home-one/blog2.jpg" alt="Blog">
                        </a>
                        <span>02 May 2020</span>
                    </div>
                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-detailhtml">Brief About How to Make Pizza</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a class="cmn-btn" routerLink="/blog-detailhtml">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="blog-item">
                    <div class="blog-top">
                        <a routerLink="/blog-detailhtml">
                            <img src="assets/img/home-one/blog3.jpg" alt="Blog">
                        </a>
                        <span>03 May 2020</span>
                    </div>
                    <div class="blog-bottom">
                        <h3><a routerLink="/blog-detailhtml">Brief About How to Make Burger</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                        <a class="cmn-btn" routerLink="/blog-detailhtml">Read More</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center">
            <a class="read-blog-btn" routerLink="/blog">Read More Blogs</a>
        </div>
    </div>
</section>

<div class="join-area join-area-two">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="join-img">
                    <img src="assets/img/home-two/join1.png" alt="Join">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="join-content">
                    <div class="section-title">
                        <h2>Join As a Delivery Man</h2>
                        <p>Restaurants range from inexpensive and informal lunching or dining places catering to people working nearby, with modest food served in simple settings at low prices.</p>
                    </div>
                    <a class="cmn-btn" routerLink="/contact">Apply Now</a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>