<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area page-title-img-one">
    <!-- <div class="container">
        <div class="page-title-item">
            <h2>Menu</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Menu</li>
            </ul>
        </div>
    </div>-->
</div>

<div class="feature-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/food-items/MisalPav.jpg" alt="Misal Pav">

                    <div class="feature-inner">
                        <ul>
                            <li><img class="menu-title-img" src="assets/img/logo.png" alt="Feature"></li>
                            <li><span>Misal Pav</span></li>
                            <!-- <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/food-items/TariiPohe.jpg" alt="TariiPohe">

                    <div class="feature-inner">
                        <ul>
                            <li><img class="menu-title-img" src="assets/img/logo.png" alt="Feature"></li>
                            <li><span>Tarii Pohe</span></li>
                            <!-- <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/food-items/BatataVada.jpg" alt="Batata Vada">

                    <div class="feature-inner">
                        <ul>
                            <li><img class="menu-title-img" src="assets/img/logo.png" alt="Feature"></li>
                            <li><span>Vada Usal Pav</span></li>
                            <!-- <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/food-items/SabudanaVada.jpg" alt="Sabudana Vada">

                    <div class="feature-inner">
                        <ul>
                            <li><img class="menu-title-img" src="assets/img/logo.png" alt="Feature"></li>
                            <li><span>Sabudana Vada</span></li>
                            <!-- <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/food-items/ThaliPith.jpg" alt="Thali Pith">

                    <div class="feature-inner">
                        <ul>
                            <li><img class="menu-title-img" src="assets/img/logo.png" alt="Feature"></li>
                            <li><span>Thali Peeth</span></li>
                            <!-- <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/food-items/Taak.jpg" alt="Taak">

                    <div class="feature-inner">
                        <ul>
                            <li><img class="menu-title-img" src="assets/img/logo.png" alt="Feature"></li>
                            <li><span>Taak</span></li>
                            <!-- <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/food-items/KairiPanhe.jpg" alt="Kairi Panhe">

                    <div class="feature-inner">
                        <ul>
                            <li><img class="menu-title-img" src="assets/img/logo.png" alt="Feature"></li>
                            <li><span>Kairi Panhe</span></li>
                            <!-- <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/food-items/Solkadhi.jpg" alt="Solkadhi">

                    <div class="feature-inner">
                        <ul>
                            <li><img class="menu-title-img" src="assets/img/logo.png" alt="Feature"></li>
                            <li><span>Solkadhi</span></li>
                            <!-- <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li> -->
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/food-items/KokamSarbat.jpg" alt="Kokam Sarbat">

                    <div class="feature-inner">
                        <ul>
                            <li><img class="menu-title-img" src="assets/img/logo.png" alt="Feature"></li>
                            <li><span>Kokam Sarbat</span></li>
                            <!-- <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li> -->
                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <div class="row mt-5">
            <div class="col-sm-6 col-lg-6">
                <div class="feature-item">
                    <img src="assets/img/food-items/menu pos-02.jpg" alt="Menu">
                </div>
            </div>
            <div class="col-sm-6 col-lg-6">
                <div class="feature-item">
                    <img src="assets/img/food-items/menu pos-01.jpg" alt="Menu">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>