<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area page-title-img-one">
    <!-- <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="page-title-item">
                    <h2>Franchise</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li>Franchise</li>
                    </ul>
                </div>
            </div>
        </div>
    </div> -->
</div>

<div class="services-details-area pt-10 pb-60">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="services-details-item">
                    <div class="services-details-fresh">
                        <h2 class="base-font">Join hands with the legendary and iconic Mamledar Misal Franchise</h2>
                        <br>
                        <h4 class="base-font">MAMLEDAR MISAL: Spicing up the city since 1952</h4>
                        <br>

                    </div>
                </div>
            </div>
        </div>
        <br><br>

        <h3 class="text-center">Why Mamledar Misal Franchise?</h3>
        <div class="row menu--list">
            <div class="col-md-4">
                <div class="single--item">
                    <img class="outlet-icon" src="assets/img/franchise/icon/brand.png" alt="Menu ">
                    <h3>Brand</h3>
                    <p>Legacy | Recognition | Trust</p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="single--item">
                    <img class="outlet-icon" src="assets/img/franchise/icon/framework.png" alt="Menu ">
                    <h3>Framework</h3>
                    <p>High Standard | Highest Credibility | SOP| Manuals</p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="single--item">
                    <img class="outlet-icon" src="assets/img/franchise/icon/support.png" alt="Menu ">
                    <h3>Support</h3>
                    <p>Business Training | Operational Training | Crew and Culture Training</p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="single--item">
                    <img class="outlet-icon" src="assets/img/franchise/icon/agreement.png" alt="Menu ">
                    <h3>Agreement</h3>
                    <p>5 Years + extendable term <br>
                        Pre-defined Territory <br>
                        Mutual transparent transaction <br>
                        Legal & Compliance Support </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="single--item">
                    <img class="outlet-icon" src="assets/img/franchise/icon/tech-support.png" alt="Menu ">
                    <h3>Technology Support</h3>
                    <p>Website Ordering System <br>
                        Customer Relation Management System <br>
                        Point of Sales System <br>
                        Delivery system </p>
                </div>
            </div>
            <div class="col-md-4">
                <div class="single--item">
                    <img class="outlet-icon" src="assets/img/franchise/icon/project-support.png" alt="Menu ">
                    <h3>Project Support</h3>
                    <p>Project Planning & Projections <br>
                        Project Execution <br>
                        Project Handover </p>
                </div>
            </div>
        </div>
        <div class="fofo-outlet">
            <h3>TYPES OF FOFO OUTLETS</h3>
            <p>Franchise owned Franchise Operated </p>
            <div class="row menu--list">
                <div class="col-md-4">
                    <div class="single--item">
                        <img class="outlet-icon" src="assets/img/franchise/fofo/master-control.png" alt="Menu ">
                        <p>Master Franchisee Central Kitchen + 3 dine-in outlets</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="single--item">
                        <img class="outlet-icon" src="assets/img/franchise/fofo/dine-in.png" alt="Menu ">
                        <p>Dine In Model 250 to 1000 Sq. ft.</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="single--item">
                        <img class="outlet-icon" src="assets/img/franchise/fofo/mall-outlet.png" alt="Menu ">
                        <p>Mall Outlet /Food Court Model 250 to 400 Sq. ft.</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="single--item">
                        <img class="outlet-icon" src="assets/img/franchise/fofo/food-truck.png" alt="Menu ">
                        <p>Food Truck Model: as per the vehicle space available </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="single--item">
                        <img class="outlet-icon" src="assets/img/franchise/fofo/cart-model.png" alt="Menu ">
                        <p>Cart Model 6 x 4 Sq. ft. cart</p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="single--item">
                        <img class="outlet-icon" src="assets/img/franchise/fofo/take-away.png" alt="Menu ">
                        <p>Take Away & Delivery Model 100 to 150 Sq ft</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-details-area point-program pt-10 pb-60">
    <div class="container">
        <img class="floating-character" src="assets/img/character/mm5.png" alt="">
        <div class="row">
            <div class="col">
                <h3>10 Points Program</h3>
                <ol>
                    <li><span>Fill out the form and get connected with the Franchise Team for a commencement call. Book
                            a Calendly app.&nbsp;</span></li>
                    <li><span>Brand Review of the application&nbsp;</span></li>
                    <li><span>One-to-one discussion&nbsp;&nbsp;</span></li>
                    <li><span>Site visit &amp; verification</span></li>
                    <li><span>Meeting to address any concerns and booking the location.&nbsp;</span></li>
                    <li><span>Final Agreement</span></li>
                    <li><span>Infrastructure Development&nbsp;</span></li>
                    <li><span>Stock Procurement&nbsp;</span></li>
                    <li><span>QSR Operational Training</span></li>
                    <li><span>Soft launch of the outlet.</span></li>
                </ol>
            </div>
        </div>
    </div>
</div>

<div class="about-area-two pt-100 pb-70">
    <div class="about-shape"><img src="assets/img/floating.png" alt="Shape"></div>

    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-img">
                    <img src="assets/img/franchise/review.png" alt="About">
                    <img src="assets/img/home-three/about2.png" alt="About">

                    <div class="video-wrap">
                        <a href="https://www.youtube.com/watch?v=yPllZnU_yIA" class="popup-youtube"><i
                                class='bx bx-play'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-content">
                    <div class="section-title">
                        <h2><span class="gradient--text">Check what our Franchise owners say about us?</span></h2>

                    </div>
                    <!-- <a class="cmn-btn" routerLink="/about">Know More</a> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div>
    <div class="container">
        <h3 class="text-center">What media say about us?</h3>
        <div class="row mt-4">
            <div class="col-sm-6 col-lg-4">
                <div class="chef-item">
                    <a target="_blank"
                        ref="https://www.mid-day.com/mumbai-guide/mumbai-food/article/mumbai-food-popular-eatery-that-served-raj-thackeray-likely-to-expand-18708194"
                        class="chef-top">
                        <img src="assets/img/articles/1.png" alt="Chef">
                    </a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="chef-item">
                    <a target="_blank" ref="https://bombayreport.com/mamledar-misal/" class="chef-top">
                        <img src="assets/img/articles/2.png" alt="Chef">
                    </a>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="chef-item">
                    <a target="_blank" ref="http://thaneliving.com/food/mamledar-misal-spicing-up-thanekars/"
                        class="chef-top">
                        <img src="assets/img/articles/3.png" alt="Chef">
                    </a>
                </div>
            </div>

        </div>
    </div>
</div>

<app-contact-form [heading]="'Get a Franchise'" [type]="'vendor'"></app-contact-form>

<app-footer-style-one></app-footer-style-one>