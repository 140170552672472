<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area page-title-img-one">
        <!-- <div class="container">
        <div class="page-title-item">
            <h2>About Us</h2>
        </div>
    </div> -->
</div>

<section class="story-area pb-70">
        <img class="floating-character" src="assets/img/character/mm.png" alt="">
        <img class="floating-character" src="assets/img/character/mm1.png" alt="">
        <img class="floating-character" src="assets/img/character/mm2.png" alt="">
        <img class="floating-character" src="assets/img/character/mm3.png" alt="">
        <img class="floating-character" src="assets/img/character/mm4.png" alt="">
        <img class="floating-character" src="assets/img/character/mm5.png" alt="">

        <div class="container">
                <div class="story-head text-justify">
                        <h2 class="text-center"><span class="gradient--text">About Us</span></h2>
                        <p>In the year 1952 a small outlet was opened near Mamledar kacheri Thane by. Mr Narsingh
                                Murdeshwar. They started serving delicacies from the Maharashtrian state of India. His
                                signature dish Misal became an instant hit and came to be enjoyed by many people in the
                                area. Because of this outlet the name Mamledar Misal became famous.</p>
                        <br>
                        <p>Later his son Mr Laxman Murdeshwar took charge and hence made it into a household name.
                                Recipes were formalized and the operation standardized. Since then the brand has gone
                                from
                                just being a household name to one with prestige. Mamledar Misal is a name known for its
                                standardisation of quality.</p>
                        <p>Today Mr Damodar Murdeshwar the third-generation entrepreneur is taking Mamledar Misal to new
                                Heights. A foodie by himself Mr Damodar Murdeshwar is the major force behind the growth
                                of
                                the Mamledar Misal brand.</p>
                        <p><span>Welcome all the Maharashtrian food lovers and specially all the fans of legendary &amp;
                                        iconic
                                        Mamledar Misal.&nbsp;</span></p><br>
                        <p><span>Mamledar Misal has a very humble beginning, the business was founded by Late Mr
                                        Narsimha Murdeshwar
                                        and his wife in 1952 post that Late Mr Laxmansheth Murdeshwar made it into a
                                        popular brand
                                        name.&nbsp;</span></p><br>
                        <p><span>In a real sense this is a people&rsquo;s brand as everyone knows that
                                </span><span>signboard-less
                                        government canteen near Mamledar Kacheri in Thane saw this drool worthy Misal
                                        being served since its
                                        inception and the people who used to que outside the Mamledar Office to eat this
                                        Misal started
                                        calling it Mamledar Misal. This</span><span> first outlet is </span><span>tucked
                                        away in a busy
                                        street beside the tehsildar&rsquo;s office in Thane, Mamledar Misal is known,
                                        arguably, for making
                                        Misal that is not only mouth watering but also famously
                                        eye-watering.&nbsp;</span></p><br>
                        <p><span>Later Laxmansheth&rsquo;s son Mr. Damodar Murdeshwar thought of taking this blazing,
                                        six-decades
                                        long legacy to other multiple locations and made sure that this soaring popular
                                        Misal is available
                                        to tickle the taste buds of all Misal Fans in different parts of Thane, Mumbai,
                                        Navi Mumbai and now
                                        at Pune too.&nbsp;</span></p><br>
                        <p><span>Currently having 20 Franchise outlets serving this Misal and gaining ever growing fan
                                        base.&nbsp;</span></p><br>
                        <p><span>The family migrated from Karwar to Thane had limited menu options to offer in the
                                        beginning,
                                        considering the demand of all fans Mamledar Misal serves mouthwatering
                                        Maharashtrian
                                        dishes.&nbsp;</span></p><br>
                        <p><span>Food is now prepared in their central kitchen in Thane and transported to multiple
                                        outlets to make
                                        sure the quality and consistency remains intact and letting its famous dish make
                                        a noise.</span></p>
                        <p><span>Mamledar Misal is now envisioning to satiate the hunger of the fans of this much
                                        coveted
                                        Maharashtrian snack across the country.&nbsp;</span></p><br>
                        <p><span>Regards!</span></p><br>
                        <p><span>Team Mamledar Misal&nbsp;</span></p>

                        <br><br>

                        <h3>FOUNDERS:</h3>
                        <ul class="mt-3 founder">
                                <li style="list-style-type: none;">
                                        <h5 style="font-family: Poppins, sans-serif;"><i
                                                        class="bx bx-chevron-right"></i> Shreya Damodar Murdeshwar</h5>
                                </li>
                                <li style="list-style-type: none;">
                                        <h5 style="font-family: Poppins, sans-serif;"><i
                                                        class="bx bx-chevron-right"></i> Damodar Laxman Murdeshwar</h5>
                                </li>
                        </ul>


                </div>

        </div>
</section>

<app-character-slider></app-character-slider>


<app-footer-style-one></app-footer-style-one>