<footer class="footer-area-two pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo-two.png" alt="Logo"></a>
                        <p>Lorem ipsum dolor sit amet, consectetur elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <div class="footer-subscribe footer-subscriber-two">
                            <input type="email" class="form-control" placeholder="Enter your email">
                            <button type="submit" class="btn footer-btn"><i class='bx bxs-send bx-flashing'></i></button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-services">
                        <h3>Services</h3>

                        <ul>
                            <li><a routerLink="/contact"><i class='bx bx-chevron-right'></i> Support</a></li>
                            <li><a routerLink="/about"><i class='bx bx-chevron-right'></i> About</a></li>
                            <li><a routerLink="/chefs"><i class='bx bx-chevron-right'></i> Chefs</a></li>
                            <li><a routerLink="/blog"><i class='bx bx-chevron-right'></i> Blog</a></li>
                            <li><a routerLink="/privacy-policy"><i class='bx bx-chevron-right'></i> Privacy Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-services">
                        <h3>Quick Links</h3>

                        <ul>
                            <li><a routerLink="/services"><i class='bx bx-chevron-right'></i> Services</a></li>
                            <li><a routerLink="/food-collection"><i class='bx bx-chevron-right'></i> Food Collection</a></li>
                            <li><a routerLink="/online-order"><i class='bx bx-chevron-right'></i> Online Order</a></li>
                            <li><a routerLink="/blog"><i class='bx bx-chevron-right'></i> Blog</a></li>
                            <li><a routerLink="/contact"><i class='bx bx-chevron-right'></i> Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-services">
                        <h3>Contact Us</h3>

                        <ul>
                            <li><a href="tel:+1123456789"><i class='bx bx-phone-call'></i> +1 1234 56 789</a></li>
                            <li><a href="tel:+5143456768"><i class='bx bx-phone-call'></i> +5 1434 56 768</a></li>
                            <li><a href="mailto:info@mamledarmisal.com"><i class='bx bx-message-detail'></i> info@mamledarmisal.com</a></li>
                            <li><a href="mailto:hello@mamledarmisal.com"><i class='bx bx-message-detail'></i> hello@mamledarmisal.com</a></li>
                            <li><i class='bx bx-location-plus'></i> Thane</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="copyright-area copyright-area-two">
    <div class="container">
        <div class="copyright-item">
            <p>Copyright @2020 Mamledar Misal. All Rights Reserved</p>
        </div>
    </div>
</div>