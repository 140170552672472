<footer class="pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>
                        <p>The Mamledar Misal recipe is a well-kept family secret, passed down from generation to
                            generation only.</p>
                        <div class="social-link">
                            <ul>
                                <li><a href="https://www.facebook.com/mamledar" target="_blank"><i
                                            class='bx bxl-facebook'></i></a></li>
                                <li><a href="https://www.instagram.com/mamledarmisal.official" target="_blank"><i
                                            class='bx bxl-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-services">
                        <h3>Information</h3>

                        <ul>
                            <li><a routerLink="/about"><i class='bx bx-chevron-right'></i> About</a></li>
                            <li><a routerLink="/franchise"><i class='bx bx-chevron-right'></i> Franchise</a></li>
                            <li><a routerLink="/press"><i class='bx bx-chevron-right'></i> Press Release</a></li>
                            <li><a routerLink="/terms-conditions"><i class='bx bx-chevron-right'></i> Terms &amp;
                                    Conditions</a></li>
                            <li><a routerLink="/privacy-policy"><i class='bx bx-chevron-right'></i> Privacy Policy</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-services">
                        <h3>Quick Links</h3>

                        <ul>
                            <li><a routerLink="/menu"><i class='bx bx-chevron-right'></i> Menu</a></li>
                            <li><a routerLink="/contact"><i class='bx bx-chevron-right'></i> Contact</a></li>
                            <li><a routerLink="/franchise"><i class='bx bx-chevron-right'></i> Franchise</a></li>

                        </ul>
                        <div class="single--item extra-space hide-mobile">
                            <a href="https://hcsglobalcorp.com/" target="_blank">
                            <img src="assets/img/hcs-logo.png" alt=""> Powered by HCS Global Corp
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-services">
                        <h3>Contact Us</h3>

                        <ul>
                            <li><a href="tel:+919324397487"><i class='bx bx-phone-call'></i> +91 9324397487</a></li>
                            <li><a href="mailto:info@mamledarmisal.com"><i class='bx bx-message-detail'></i>
                                    info@mamledarmisal.com</a></li>
                            <li><i class='bx bx-location-plus'></i> <a href="https://goo.gl/maps/iBhUcxKGn3W8c6yz8"
                                    target="
                                ">Opposite Zilla Parishad, Naupada, Thane</a></li>
                        </ul>

                        <div class="single--item extra-space hide-desktop">
                            <a href="https://hcsglobalcorp.com/" target="_blank">
                            <img src="assets/img/hcs-logo.png" alt=""> Powered by HCS Global CORP
                            </a>
                        </div>

                        <div class="single--item">
                            <img src="assets/img/gns-logo.png" alt=""> Artworks designed by GNS
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-item">
            <p class="copyright-item-akro">Crafted with <img draggable="false" width="20" height="20" role="img"
                    class="emoji" alt="❤" src="https://s.w.org/images/core/emoji/13.1.0/svg/2764.svg"> by Akrotics</p>
            <p class="copyright-item-mm">&copy; 2023 Mamledar Misal. All Rights Reserved.</p>
        </div>
    </div>
</div>