<app-navbar-style-one></app-navbar-style-one>

<div class="banner-area">
    <div class="banner-shape">
        <!-- <img src="assets/img/home-one/banner/shape1.png" alt="Shape"> -->
        <!-- <img src="assets/img/home-one/banner/shape2.png" alt="Shape"> -->
        <!-- <img src="assets/img/home-one/banner/shape3.png" alt="Shape"> -->
        <!-- <img src="assets/img/home-one/banner/banner-shape.png" alt="Shape"> -->
    </div>

    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>Now Order Your Favourite Misal Online!</h1>
                            <p>You guessed it right! <strong>Mamledar Misal</strong> is now just a click away. Quench
                                your masaledar misal craving by ordering your favourite misal through our website and
                                also through food delivery apps Zomato and Swiggy. <i>Never Stay Away</i> from a good
                                spicy mouth-watering <strong>Mamledar Misal.</strong></p>
                            <form class="d-none">
                                <input type="text" class="form-control" placeholder="Enter food name">
                                <button type="submit" class="btn banner-form-btn">Search Now</button>
                            </form>
                        </div>
                    </div>

                    <div class="col-lg-6 banner-slider-container">
                        <div class="banner-slider owl-theme owl-carousel">
                            <div class="slider-item">
                                <!-- <img src="assets/img/home-one/banner/banner-slider1.png" alt="Slider"> -->
                                <img src="assets/img/hot.png" alt="Slider">
                            </div>

                            <div class="slider-item">
                                <!-- <img src="assets/img/home-one/banner/banner-slider2.png" alt="Slider"> -->
                                <img src="assets/img/spicy.png" alt="Slider">
                            </div>

                            <div class="slider-item">
                                <!-- <img src="assets/img/home-one/banner/banner-slider3.png" alt="Slider"> -->
                                <img src="assets/img/tangy.png" alt="Slider">
                            </div>
                            <div class="slider-item">
                                <!-- <img src="assets/img/home-one/banner/banner-slider3.png" alt="Slider"> -->
                                <img src="assets/img/its-mamledar.png" alt="Slider">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="feature-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Mamledar Special
                <!-- <img width="60" height="39" src="assets/img/home-one/banner/shape2.png" alt="Shape"> -->
            </h2>
            <p class="mspl-box">What makes Mamledar's misal extra special is the three varieties, categorised according
                to the levels of heat — light, medium and very spicy; each level is adjusted using the special tari (a
                spice-laden watery gravy) on the side.</p>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/home-one/spicy-misal.png" alt="Feature">

                    <div class="feature-inner">
                        <ul>
                            <!-- <li><img src="assets/img/home-one/spicy-misal.png" alt="Feature"></li> -->
                            <li><span>Order Now</span></li>
                            <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/home-one/tarri-pohe.png" alt="Feature">

                    <div class="feature-inner">
                        <ul>
                            <!-- <li><img src="assets/img/home-one/tarri-pohe.png" alt="Feature"></li> -->
                            <li><span>Order Now</span></li>
                            <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="feature-item">
                    <img src="assets/img/home-one/vada-usal.png" alt="Feature">

                    <div class="feature-inner">
                        <ul>
                            <!-- <li><img src="assets/img/home-one/vada-usal.png" alt="Feature"></li> -->
                            <li><span>Order Now</span></li>
                            <li><a routerLink="/"><i class='bx bx-right-arrow-alt'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Menu</h2>
            <p>We enjoy serving smiles and misals to our beloved customers. Although, Mamledar’s Menu consists more than
                just its Star Misal. We have various snacks and beverages to suit your many cravings.</p>
        </div>

        <div class="services-slider services-slider-1 owl-theme owl-carousel">
            <div class="services-item">
                <a routerLink="/services-details">
                    <img src="assets/img/home-one/home-our-menu/Snacks.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>Snacks</h3>
                    <p>Mamledar Misal Pav, Vada Usal Pav, etc.</p>
                </a>
            </div>

            <div class="services-item">
                <a routerLink="/services-details">
                    <img src="assets/img/home-one/home-our-menu/Sweet.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>Sweet</h3>
                    <p>Kharwas</p>
                </a>
            </div>

            <div class="services-item">
                <a routerLink="/services-details">
                    <img src="assets/img/home-one/home-our-menu/Beverages.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>Beverages</h3>
                    <p>Buttermilk, Kairi Panha, Kokam Sarbat, etc.</p>
                </a>
            </div>

            <div class="services-item">
                <a routerLink="/services-details">
                    <img src="assets/img/home-one/home-our-menu/Only Morning Snacks.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <h3>Only Morning Snacks</h3>
                    <p>Tari Pohe MM Special, Sheera, Upma, etc.</p>
                </a>
            </div>
        </div>
    </div>
</section>

<div class="restant-area">
    <!-- <div class="restant-shape"><img src="assets/img/home-one/services-shape2.png" alt="Shape"></div> -->

    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="restant-img">
                    <img src="assets/img/home-page-misal.jpg" alt="misal-plate">
                    <img src="assets/img/home-one/onion.png" alt="onion">
                    <img src="assets/img/home-one/potato.png" alt="potato">
                    <img src="assets/img/home-one/peas.png" alt="peas">
                    <img src="assets/img/home-one/red-chilli.png" alt="red-chilli">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="restant-content">
                    <div class="section-title">
                        <h2><span class="gradient--text">Mamledar Special - </span> <br> <span
                                class="gradient--text">Tarri Marr Ke</span></h2>
                        <p>The Mamledar Misal recipe is a well-kept family secret, passed down from generation to
                            generation only. In fact, the farsan used in the misal is made in-house too. The recipe was
                            invented by Mr. Narsinha Murdeshwar's wife. She would blend all the spices for the misal
                            masala by her own hand. Her recipe is still used today, and remains within the family.</p>
                    </div>
                    <a class="cmn-btn" routerLink="/about">Know More</a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <section class=" collection-area pb-100 d-none">
    <div class="container ">
        <div class="section-title ">
            <h2>Our Regular Food Collections</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua.</p>
        </div>

        <div class="sorting-menu ">
            <ul>
                <li class="filter active " data-filter="all ">All</li>
                <li class="filter " data-filter=".web ">Fast Food</li>
                <li class="filter " data-filter=".ui ">Platters</li>
                <li class="filter " data-filter=".ux ">Dessert</li>
                <li class="filter " data-filter=".branding ">Asian Food</li>
            </ul>
        </div>

        <div id="Container " class="row ">
            <div class="col-sm-6 col-lg-3 mix web ui ">
                <div class="collection-item ">
                    <div class="collection-top ">
                        <img src="assets/img/home-one/collection/1.jpg " alt="Collection ">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart ">
                            <a routerLink="/cart "><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>

                    <div class="collection-bottom ">
                        <h3>Dark Chocolate Cake</h3>
                        <ul>
                            <li><span>$25</span></li>
                            <li>
                                <div class="number ">
                                    <span class="minus ">-</span>
                                    <input type="text " class="form-control " value="1 " />
                                    <span class="plus ">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix ui ">
                <div class="collection-item ">
                    <div class="collection-top ">
                        <img src="assets/img/home-one/collection/2.jpg " alt="Collection ">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart ">
                            <a routerLink="/cart "><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom ">
                        <h3>Cake with Drinks</h3>
                        <ul>
                            <li><span>$15</span></li>
                            <li>
                                <div class="number ">
                                    <span class="minus ">-</span>
                                    <input type="text " class="form-control " value="1 " />
                                    <span class="plus ">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix ux ui ">
                <div class="collection-item ">
                    <div class="collection-top ">
                        <img src="assets/img/home-one/collection/3.jpg " alt="Collection ">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart ">
                            <a routerLink="/cart "><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom ">
                        <h3>Doughnut Chocolate</h3>
                        <ul>
                            <li><span>$20</span></li>
                            <li>
                                <div class="number ">
                                    <span class="minus ">-</span>
                                    <input type="text " class="form-control " value="1 " />
                                    <span class="plus ">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix branding web ">
                <div class="collection-item ">
                    <div class="collection-top ">
                        <img src="assets/img/home-one/collection/4.jpg " alt="Collection ">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart ">
                            <a routerLink="/cart "><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom ">
                        <h3>Dark Chocolate Cake</h3>
                        <ul>
                            <li><span>$23</span></li>
                            <li>
                                <div class="number ">
                                    <span class="minus ">-</span>
                                    <input type="text " class="form-control " value="1 " />
                                    <span class="plus ">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix web branding ">
                <div class="collection-item ">
                    <div class="collection-top ">
                        <img src="assets/img/home-one/collection/5.jpg " alt="Collection ">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart ">
                            <a routerLink="/cart "><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom ">
                        <h3>Sweet Dougnuts</h3>
                        <ul>
                            <li><span>$35</span></li>
                            <li>
                                <div class="number ">
                                    <span class="minus ">-</span>
                                    <input type="text " class="form-control " value="1 " />
                                    <span class="plus ">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix ui web ">
                <div class="collection-item ">
                    <div class="collection-top ">
                        <img src="assets/img/home-one/collection/6.jpg " alt="Collection ">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart ">
                            <a routerLink="/cart "><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom ">
                        <h3>Birthday Cake</h3>
                        <ul>
                            <li><span>$32</span></li>
                            <li>
                                <div class="number ">
                                    <span class="minus ">-</span>
                                    <input type="text " class="form-control " value="1 " />
                                    <span class="plus ">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix ux branding ">
                <div class="collection-item ">
                    <div class="collection-top ">
                        <img src="assets/img/home-one/collection/7.jpg " alt="Collection ">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart ">
                            <a routerLink="/cart "><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom ">
                        <h3>Chocolate Ice Cream</h3>
                        <ul>
                            <li><span>$28</span></li>
                            <li>
                                <div class="number ">
                                    <span class="minus ">-</span>
                                    <input type="text " class="form-control " value="1 " />
                                    <span class="plus ">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 mix branding ui ">
                <div class="collection-item ">
                    <div class="collection-top ">
                        <img src="assets/img/home-one/collection/8.jpg " alt="Collection ">
                        <ul>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                            <li><i class='bx bxs-star checked'></i></li>
                        </ul>
                        <div class="add-cart ">
                            <a routerLink="/cart "><i class='bx bxs-cart'></i> Add to Cart</a>
                        </div>
                    </div>
                    <div class="collection-bottom ">
                        <h3>Dark Chocolate Cake</h3>
                        <ul>
                            <li><span>$27</span></li>
                            <li>
                                <div class="number ">
                                    <span class="minus ">-</span>
                                    <input type="text " class="form-control " value="1 " />
                                    <span class="plus ">+</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="more-collection ">
            <a routerLink="/food-collection ">View More Colletction</a>
        </div>
    </div>
</section> -->

<section class="menu-area pt-100 pb-70 ">
    <div class="container ">
        <div class="section-title ">
            <h2>Our Latest Outlets</h2>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
        </div>

        <div class="row ">
            <a href="https://goo.gl/maps/Qw3n7PVitcVXQ4YK7" target="_blank"
                class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 ">
                <div class="menu-item outlet-box">
                    <img class="menu-shape " src="assets/img/home-one/services-shape.png " alt="Menu ">
                    <img src="assets/img/icon/BOARD-DESIGN-1.png" alt="Menu ">
                    <h3>Mamledar Misal <br> Chembur</h3>
                </div>
            </a>
            <a href="https://goo.gl/maps/cb18GKzB7bPJmNVy7" target="_blank" class="col-sm-6 col-lg-4 ">
                <div class="menu-item active outlet-box">
                    <img class="menu-shape " src="assets/img/home-one/services-shape.png" alt="Menu ">
                    <img src="assets/img/icon/BOARD-DESIGN-2.png" alt="Menu ">
                    <h3>Mamledar Misal <br> Vasant Vihar</h3>
                </div>
            </a>

            <a href="https://goo.gl/maps/Wrim4nVReeM5MbyF9" target="_blank" class="col-sm-6 col-lg-4 ">
                <div class="menu-item outlet-box">
                    <img class="menu-shape " src="assets/img/home-one/services-shape.png " alt="Menu ">
                    <img src="assets/img/icon/BOARD-DESIGN-3.png" alt="Menu ">
                    <h3>Mamledar Misal <br> Kothurd</h3>
                </div>
            </a>


        </div>
    </div>
</section>

<section class="reservation-area ">
    <div class="reservation-shape "><img src="assets/img/home-one/reservation-shape.png " alt="Shape "></div>

    <div class="container ">
        <div class="row align-items-center ">
            <div class="col-lg-6 ">
                <div class="reservation-item ">
                    <div class="section-title ">
                        <h2>Join the Mamledar Family</h2>
                        <p class="join-box-p">The recipe is a family secret. Are you ready to be a part of our
                            journey...</p>
                        <br>
                        <a routerLink="/franchise" class="btn cmn-btn ">Join Now</a>
                    </div>
                    <!-- <form>
                        <ul>
                            <li>
                                <input type="date " class="form-control " name="arrive ">
                            </li>
                            <li>
                                <input type="time " class="form-control " name="arrive ">
                            </li>
                            <li>
                                <button type="submit " class="btn cmn-btn ">Reserve Now</button>
                            </li>
                        </ul>
                    </form> -->
                </div>
            </div>

            <div class="col-lg-6 ">
                <div class="reservation-img ">
                    <!-- <img src="assets/img/home-one/reservation.png " alt="Reservation "> -->
                    <img src="assets/img/home-one/chilli-circle.png" alt="Reservation ">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="d-none chef-area pb-70 ">
    <div class="container ">
        <div class="section-title ">
            <h2>Our Management</h2>
            <p class="d-none">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                ut labore et dolore magna aliqua.</p>
        </div>

        <div class="row d-flex justify-content-center">
            <div class="col-sm-6 col-lg-3 ">
                <div class="chef-item ">
                    <div class="chef-top ">
                        <img src="assets/img/home-one/chef/1.jpg " alt="Chef ">
                        <div class="chef-inner ">
                            <h3>Damodar L. Murdeshwar</h3>
                            <span>Proprietor</span>
                        </div>
                    </div>
                    <div class="chef-bottom ">
                        <ul>
                            <li><a target="_blank " href="# "><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank " href="# "><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank " href="# "><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 ">
                <div class="chef-item ">
                    <div class="chef-top ">
                        <img src="assets/img/home-one/chef/6.jpg " alt="Chef ">
                        <div class="chef-inner ">
                            <h3>Ashutosh Damodar Murdeshwar</h3>
                            <span>Senior Management</span>
                        </div>
                    </div>
                    <div class="chef-bottom ">
                        <ul>
                            <li><a target="_blank " href="# "><i class='bx bxl-facebook'></i></a></li>
                            <li><a target="_blank " href="# "><i class='bx bxl-twitter'></i></a></li>
                            <li><a target="_blank " href="# "><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="review-area about-area-two">
    <div class="container-fluid p-0 ">
        <div class="row m-0 align-items-center ">
            <div class="col-lg-6 p-0 ">
                <div class="review-img ">
                    <div class="about-img">
                        <img src="assets/img/home-one/youtube-thumbnail.png" alt="About">
                        <!-- <img src="assets/img/home-three/about2.png" alt="About"> -->

                        <div class="video-wrap">
                            <a href="https://www.youtube.com/watch?v=LvcCzwnstA8" class="popup-youtube"><i
                                    class='bx bx-play'></i></a>
                        </div>
                    </div>
                    <!-- <img src="assets/img/home-one/review1.png " alt="Review "> -->
                    <!-- <a target="_blank" href="https://www.youtube.com/watch?v=LvcCzwnstA8"><img
                            src="assets/img/home-one/youtube-thumbnail.png " alt="Review "></a> -->
                </div>
            </div>

            <div class="col-lg-6 p-0 ">
                <div class="review-item ">
                    <div class="section-title ">
                        <h2>What People Say About Us</h2>
                    </div>

                    <div class="slider-nav d-none">
                        <div class="item ">
                            <img src="assets/img/home-one/review3.png " alt="image " draggable="false " />
                        </div>

                        <div class="item ">
                            <img src="assets/img/home-one/review4.png " alt="image " draggable="false " />
                        </div>

                        <div class="item ">
                            <img src="assets/img/home-one/review5.png " alt="image " draggable="false " />
                        </div>

                        <div class="item ">
                            <img src="assets/img/home-one/review6.png " alt="image " draggable="false " />
                        </div>

                        <div class="item ">
                            <img src="assets/img/home-one/review7.png " alt="image " draggable="false " />
                        </div>

                        <div class="item ">
                            <img src="assets/img/home-one/review8.png " alt="image " draggable="false " />
                        </div>
                    </div>

                    <div class="slider-for ">
                        <div class="item ">
                            <h3>Shruti Nair</h3>
                            <p>So far, the best Misal I've tasted. After the Chembur branch opened, I was overjoyed.
                                Near the railway station, Panch pakdi, one of Thane's oldest & most spicy misal
                                locations. The ground-floor takeout order and the first-floor hotel order...</p>
                        </div>

                        <div class="item ">
                            <h3>Bala CS</h3>
                            <p>Fantastic meals.. But do not try spice..🔥 Medium or less spice are the beat options...
                                But it tastes superb</p>
                        </div>

                        <div class="item ">
                            <h3>Chintan Thakar</h3>
                            <p>Qaint little place tucked away somewhere in Thane City lies this place. The staff: 5/5
                                The food: 5/5 Hygiene: 5/5 The lassi could have been a little better but the super
                                delicious Missal Pav and Sol Kadhi made up for it. Highly recommended to all Missal Paav
                                lovers. जय महाराष्ट्र।</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-area ptb-100 d-none">
    <div class="container ">
        <div class="section-title ">
            <h2>Our Regular Blogs</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore et dolore
                magna aliqua.</p>
        </div>

        <div class="row ">
            <div class="col-sm-6 col-lg-4 ">
                <div class="blog-item ">
                    <div class="blog-top ">
                        <a routerLink="/blog-detailhtml ">
                            <img src="assets/img/home-one/blog1.jpg " alt="Blog ">
                        </a>
                        <span>01 May 2020</span>
                    </div>
                    <div class="blog-bottom ">
                        <h3><a routerLink="/blog-detailhtml ">Brief About How to Make Pasta</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore</p>
                        <a class="cmn-btn " routerLink="/blog-detailhtml ">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 ">
                <div class="blog-item ">
                    <div class="blog-top ">
                        <a routerLink="/blog-detailhtml ">
                            <img src="assets/img/home-one/blog2.jpg " alt="Blog ">
                        </a>
                        <span>02 May 2020</span>
                    </div>
                    <div class="blog-bottom ">
                        <h3><a routerLink="/blog-detailhtml ">Brief About How to Make Pizza</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore</p>
                        <a class="cmn-btn " routerLink="/blog-detailhtml ">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 ">
                <div class="blog-item ">
                    <div class="blog-top ">
                        <a routerLink="/blog-detailhtml ">
                            <img src="assets/img/home-one/blog3.jpg " alt="Blog ">
                        </a>
                        <span>03 May 2020</span>
                    </div>
                    <div class="blog-bottom ">
                        <h3><a routerLink="/blog-detailhtml ">Brief About How to Make Burger</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                            labore</p>
                        <a class="cmn-btn " routerLink="/blog-detailhtml ">Read More</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center ">
            <a class="read-blog-btn " routerLink="/blog ">Read More Blogs</a>
        </div>
    </div>
</section>

<app-character-slider></app-character-slider>

<app-footer-style-one></app-footer-style-one>