<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area page-title-img-one">
    <!-- <div class="container">
        <div class="page-title-item">
            <h2>Contact Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div> -->
</div>

<div class="contact-location-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="location-item">
                    <img src="assets/img/home-one/services-shape.png" alt="Location">
                    <i class='bx bxs-envelope'></i>
                    <ul>
                        <li><a href="mailto:info@mamledarmisal.com">info@mamledarmisal.com</a></li>
                        <li><a href="mailto:franchise@mamledarmisal.com">franchise@mamledarmisal.com</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="location-item active">
                    <img src="assets/img/home-one/services-shape.png" alt="Location">
                    <i class='bx bxs-location-plus'></i>
                    <ul>
                        <li>
                            <a href="https://goo.gl/maps/iBhUcxKGn3W8c6yz8" target="
                            ">Opposite Zilla Parishad, <br> Naupada, Thane</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="location-item">
                    <img src="assets/img/home-one/services-shape.png" alt="Location">
                    <i class='bx bxs-phone-call'></i>
                    <ul>
                        <li><a href="tel:+919324397487">+91 9324397487</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<app-contact-form [heading]="'Get in touch'"></app-contact-form>
<app-footer-style-one></app-footer-style-one>