<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area page-title-img-one">
    <!-- <div class="container">
        <div class="page-title-item">
            <h2>Privacy Policy</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Privacy Policy</li>
            </ul>
        </div>
    </div> -->
</div>

<section class="privacy-area pt-100">
    <div class="container">
        <div class="privacy-item">
            <h2>This Privacy Policy applies to the mamledarmisal.com
            </h2>

        </div>
        <p>mamledarmisal.com recognises the importance of maintaining your privacy. We value your privacy and appreciate
            your trust in us. This Policy describes how we treat user information we collect on
            http://www.mamledarmisal.com and other offline sources. This Privacy Policy applies to current and former
            visitors to our website and to our online customers. By visiting and/or using our website, you agree to this
            Privacy Policy.</p>
        <p>mamledarmisal.com is a property of Aashita Restaurant & Food Products Pvt. Ltd, an Indian Company registered
            under the Companies Act, 2013 having its registered office at B401 Aspen Raheja Garden, LBS Marg Thane,
            Maharashtra 400604 India.
        </p>
        <p><b>Information we collect</b>
        </p>
        <p><b>Contact information.</b> We might collect your name, email, mobile number, phone number, street, city,
            state, pincode, country and ip address.
        </p>
        <p><b>Payment and billing information.</b> We might collect your billing name, billing address and payment
            method when you buy a ticket. We NEVER collect your credit card number or credit card expiry date or other
            details pertaining to your credit card on our website. Credit card information will be obtained and
            processed by our online payment partner CC Avenue.</p>

        <p><strong>Information you post.&nbsp;</strong>We collect information you post in a public space on our website
            or on a third-party social media site belonging to mamledarmisal.com.</p>
        <p><strong>Demographic information.</strong> We may collect demographic information about you, events you like,
            events you intend to participate in, tickets you buy, or any other information provided by you during the
            use of our website. We might collect this as a part of a survey also.</p>
        <p><strong>Other information.</strong> If you use our website, we may collect information about your IP address
            and the browser you&apos;re using. We might look at what site you came from, duration of time spent on our
            website, pages accessed or what site you visit when you leave us. We might also collect the type of mobile
            device you are using, or the version of the operating system your computer or device is running.</p>
        <p><strong>We collect information in different ways.</strong></p>
        <p><strong>We collect information directly from you.</strong> We collect information directly from you when you
            register for an event or buy tickets. We also collect information if you post a comment on our websites or
            ask us a question through phone or email.</p>
        <p><strong>We collect information from you passively.</strong> We use tracking tools like Google Analytics,
            Google Webmaster, browser cookies and web beacons for collecting information about your usage of our
            website.</p>
        <p><strong>We get information about you from third parties.</strong> For example, if you use an integrated
            social media feature on our websites. The third-party social media site will give us certain information
            about you. This could include your name and email address.<br><br><br><strong>Use of your personal
                information</strong><br><strong>We use information to contact you:</strong> We might use the information
            you provide to contact you for confirmation of a purchase on our website or for other promotional purposes.
        </p>
        <p><strong>We use information to respond to your requests or questions.</strong> We might use your information
            to confirm your registration for an event or contest.</p>
        <p><strong>We use information to improve our products and services.</strong> We might use your information to
            customize your experience with us. This could include displaying content based upon your preferences.</p>
        <p><strong>We use information to look at site trends and customer interests.</strong> We may use your
            information to make our website and products better. We may combine information we get from you with
            information about you we get from third parties.</p>
        <p><strong>We use information for security purposes.</strong> We may use information to protect our company, our
            customers, or our websites.</p>
        <p><strong>We use information for marketing purposes.</strong> We might send you information about special
            promotions or offers. We might also tell you about new features or products. These might be our own offers
            or products, or third-party offers or products we think you might find interesting. Or, for example, if you
            buy tickets from us we&apos;ll enroll you in our newsletter.</p>
        <p><strong>We use information to send you transactional communications.</strong> We might send you emails or SMS
            about your account or a ticket purchase.</p>
        <p>We use information as otherwise permitted by law.<br><br><strong>Sharing of information with
                third-parties</strong></p>
        <p><strong>We will share information with third parties who perform services on our behalf.&nbsp;</strong>We
            share information with vendors who help us manage our online registration process or payment processors or
            transactional message processors. Some vendors may be located outside of India.</p>
        <p><strong>We will share information with the event organizers.</strong> We share your information with event
            organizers and other parties responsible for fulfilling the purchase obligation. The event organizers and
            other parties may use the information we give them as described in their privacy policies.</p>
        <p><strong>We will share information with our business partners.&nbsp;</strong>This includes a third party who
            provides or sponsors an event, or who operates a venue where we hold events. Our partners use the
            information we give them as described in their privacy policies.</p>
        <p><strong>We may share information if we think we have to in order to comply with the law or to protect
                ourselves.</strong> We will share information to respond to a court order or subpoena. We may also share
            it if a government agency or investigatory body requests. Or, we might also share information when we are
            investigating potential fraud.</p>
        <p><strong>We may share information with any successor to all or part of our business.&nbsp;</strong>For
            example, if part of our business is sold we may give our customer list as part of that transaction.</p>
        <p><strong>We may share your information for reasons not described in this policy.</strong> We will tell you
            before we do this.</p>
        <p><strong>Email Opt-Out</strong></p>
        <p><strong>You can opt out of receiving our marketing emails.</strong> To stop receiving our promotional emails,
            please email <a data-fr-linked="true" href="mailto:info@mamledarmisal.com">info@mamledarmisal.com</a>. It
            may take about ten days to process your request. Even if you opt out of getting marketing messages, we will
            still be sending you transactional messages through email and SMS about your purchases. </p>
        <p><strong>Third party sites</strong></p>
        <p>If you click on one of the links to third party websites, you may be taken to websites we do not control.
            This policy does not apply to the privacy practices of those websites. Read the privacy policy of other
            websites carefully. We are not responsible for these third party sites.<br><br><strong>Grievance
                Officer</strong></p>
        <p>In accordance with Information Technology Act 2000 and rules made there under, the name and contact details
            of the Grievance Officer are provided below:<br>Mr. <a href="mailto:director@mamledarmisal.com">Ashutosh
                Murdeshwar</a><br>B401 Aspen Raheja Garden, LBS Marg Thane Maharashtra 400604 India<br>Phone: +91 22
            6792 1974<br>Email: <a data-fr-linked="true"
                href="mailto:info@mamledarmisal.com">info@mamledarmisal.com</a><br>If you have any questions about this
            Policy or other privacy concerns, you can also email us at <a data-fr-linked="true"
                href="mailto:info@mamledarmisal.com">info@mamledarmisal.com</a><br><br><strong>Updates to this
                policy</strong></p>
        <p>This Privacy Policy was last updated on 16.03.2023. From time to time we may change our privacy practices. We
            will notify you of any material changes to this policy as required by law. We will also post an updated copy
            on our website. Please check our site periodically for updates.<br><br><strong>Jurisdiction</strong></p>
        <p>If you choose to visit the website, your visit and any dispute over privacy is subject to this Policy and the
            website&apos;s terms of use. In addition to the foregoing, any disputes arising under this Policy shall be
            governed by the laws of Maharashtra, India.<br><br></p>
    </div>
</section>

<app-footer-style-one></app-footer-style-one>