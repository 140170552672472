<app-navbar-style-three></app-navbar-style-three>

<section class="error-area">
    <div class="error-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="error-text">
                    <h1>404!</h1>
                    <p>Sorry! The Page Not Found</p>
                    <span>Oops! The page you are looking for does not exit. it might been moved or deleted.</span>
                    <a routerLink="/">Return to Home</a>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>