<app-navbar-style-one></app-navbar-style-one>

<div class="page-title-area page-title-img-one">

</div>

<div class="feature-area pt-50 pb-70">
    <div class="container">
        <div class="row ">

            <ng-container *ngFor="let outlet of outletList">
                <a href={{outlet.Map}} target="_blank" class="col-sm-6 col-lg-3 ">
                    <div class="menu-item outlet-box">
                        <img class="menu-shape " src="assets/img/home-one/services-shape.png" alt="Menu ">
                        <img class="outlet-icon" src="assets/img/logo.png " alt="Menu ">
                        <h3>Mamledar Misal <br> {{ outlet.Location }}</h3>
                    </div>
                </a>
            </ng-container>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>