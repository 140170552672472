<section class="subscribe-area services-area">
    <div class="subscribe-shape "><img src="assets/img/home-one/reservation-shape.png " alt="Shape "></div>

    <div class="container-fluid">
        <div class="services-slider charater-slider owl-theme owl-carousel">
            <div class="services-item">
                <a>
                    <img src="assets/img/character/mm.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <div>
                        <h3>Miss Crux</h3>
                        <p>Chili Pepper, Green Chili, Hari Mirch. <br>
                            The Portuguese introduced India with the green chillies, and we are hooked ever since. Can
                            something
                            so hot ever be helpful in reducing inflammation? Let's find out and have a bite of Miss
                            Crux...
                            Meet the fiery Crux of our fiery Misal! O!</p>
                    </div>
                </a>
            </div>
            <div class="services-item">
                <a>
                    <img src="assets/img/character/mm1.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <div>
                        <h3>The Boss</h3>
                        <p>Chili pepper, Red Chili, Laal Mirch <br>
                            The hottest ingredient in anyone's kitchen and the pride of India - The Boss. <br>
                            India is the largest chilli producing nation in this world; and this more than abundant
                            ingredient certainly adds spice to our Misal. <br>
                            The real taste of missal is brought out by these fiery red chillies. <br>
                            Be careful before kissing the Hot Boss! <br>
                        </p>
                    </div>
                </a>
            </div>
            <div class="services-item">
                <a>
                    <img src="assets/img/character/mm2.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <div>
                        <h3>Mr. Peallion</h3>
                        <p>Green Peas, Matar, Vatana <br>
                            What will you chose Quantity or Quality? Why not both? India is the largest producer of peas
                            in
                            the whole world and our peas are certainly of greatquality. So be Hap-pea. Mr. Peallion is
                            the
                            driving force of our Misal. <br>
                            May the Peas be with you!
                        </p>
                    </div>
                </a>
            </div>
            <div class="services-item">
                <a>
                    <img src="assets/img/character/mm3.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <div>
                        <h3>Mr. Spuddy</h3>
                        <p>Batata, Aloo<br>
                            Mr. Spuddy has travelled thousands of miles from Peru and centuries of time to be a part of
                            our
                            Indian diet.
                            Today, Mr. Spuddy is the soul of our Misal, the one ingredient that spuds joy in every bite.
                            Go ahead, tell him how Spudtacular he is!</p>
                    </div>
                </a>
            </div>
            <div class="services-item">
                <a>
                    <img src="assets/img/character/mm4.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <div>
                        <h3>Mr. Onino</h3>
                        <p>Chili Pepper, Green Chili, Hari Mirch. <br>
                            Onion, Pyaaj, Kaanda Probably the only vegetable that can bring Indian kitchen to a
                            stand-still
                            with its absence. Mr. Onino once even brought Delhi to a stand-still when its price
                            skyrocketed.
                            Hence, our kitchen and our Misal always hold Mr. Onino dearly. <br>
                            Our Love for Misal goes on-ion on!</p>
                    </div>
                </a>
            </div>
            <div class="services-item">
                <a>
                    <img src="assets/img/character/mm5.png" alt="services-image">
                    <img class="service-shape" src="assets/img/home-one/services-shape.png" alt="services-image">
                    <div>
                        <h3>The Buddy</h3>
                        <p>Bread, Pao <br>
                            Another gift of the Portuguese the bread or the pao took its sweet time to rise across
                            India.
                            The Buddy is the soft spot of our Misal and the loaf of our life. <br>
                            Yaha Zindagi ki Khushiyan Pao!
                        </p>
                    </div>
                </a>
            </div>
        </div>
        <!-- <div class="row align-items-center ">
            <div class="col-lg-7 ">
                <div class="subscribe-item ">
                    <div class="section-title ">
                        <h2>Subscribe News Letter for Get Update</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do tempor incididunt ut labore
                            et dolore magna aliqua.</p>
                    </div>

                    <form class="newsletter-form " data-toggle="validator ">
                        <input type="email " class="form-control " placeholder="Enter your email " name="EMAIL "
                            required autocomplete="off ">
                        <button class="btn cmn-btn " type="submit ">Subscribe</button>
                    </form>

                    <div class="social-link ">
                        <ul>
                            <li><a href="https://www.facebook.com/mamledar" target="_blank "><i
                                        class='bx bxl-facebook'></i></a></li>
                            <li><a href="https://www.instagram.com/mamledarmisal.official" target="_blank "><i
                                        class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-5 ">
                <div class="subscribe-img ">
                    <img src="assets/img/home-one/bread.png" alt="Subscribe ">
                </div>
            </div>
        </div> -->
    </div>
</section>