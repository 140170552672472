import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private api = 'https://mm-api-kappa.vercel.app/api/v1/contact';

  constructor(private http: HttpClient) { }

  PostMessage(input: any) {
    return this.http.post(this.api, input, { responseType: 'json' }).pipe(
      map(
        response => {
          if (response) {
            return response;
          }
        },
        error => {
          return error;
        }
      )
    )
  }
}
