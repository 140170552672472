import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outlets',
  templateUrl: './outlets.component.html',
  styleUrls: ['./outlets.component.scss']
})
export class OutletsComponent implements OnInit {

  outletList = [
    {
      "Location": "Kalyan",
      "Map": "https://www.google.com/maps/place/Mamledar+Misal+-+Kalyan+(W)/@19.2504132,73.1386283,17.15z/data=!4m6!3m5!1s0x3be79528364ddb05:0xdeb52123979842ff!8m2!3d19.2504132!4d73.1409637!16s%2Fg%2F11n0k5m8nh"
    },
    {
      "Location": "Dokhali",
      "Map": "https://www.google.com/maps/place/Mamledar+Misal+Dhokali/@19.2254487,72.9784752,17.1z/data=!4m6!3m5!1s0x3be7b992e52959cb:0xbbc03b539221d56c!8m2!3d19.225387!4d72.9808202!16s%2Fg%2F11f5nrqbcy"
    },
    {
      "Location": "Chembur",
      "Map": "https://www.google.com/maps/place/Mamledar+Misal/@19.0608867,72.8981389,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7c71dee852d31:0xc6379616e42b5bdb!8m2!3d19.0608867!4d72.9003276!16s%2Fg%2F11g1973__p"
    },
    {
      "Location": "Kharghar",
      "Map": "https://www.google.com/maps/place/Mamledar+Misal/@19.036818,73.0574012,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7c3bc4f2898db:0x2f5888a38b1b5c55!8m2!3d19.036818!4d73.0595899!16s%2Fg%2F11nnprp3xt"
    },
    {
      "Location": "Bhivandi",
      "Map": "https://www.google.com/maps/dir//Jakat+Naka,+Ground+Floor,+Sairaj,+Bhiwandi,+Maharashtra+421302/@19.2981005,72.9947402,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3be7bd9c4c81c6e5:0x26b573949820b1f6!2m2!1d73.0648049!2d19.2980928"
    },
    {
      "Location": "Dombivli (Palava)",
      "Map": "https://www.google.com/maps/place/Mamledar+Misal+Palava+-+Dombivli/@19.1699475,73.0782642,17z/data=!4m14!1m7!3m6!1s0x3be7bfabe48e4bbb:0xaec6df340a25e388!2sMamledar+Misal+Palava+-+Dombivli!8m2!3d19.1699475!4d73.0804529!16s%2Fg%2F11fhkmvzyl!3m5!1s0x3be7bfabe48e4bbb:0xaec6df340a25e388!8m2!3d19.1699475!4d73.0804529!16s%2Fg%2F11fhkmvzyl"
    },
    {
      "Location": "Kasheli",
      "Map": "https://www.google.com/maps/place/Mamledar+Misal/@19.2391796,73.014588,18.28z/data=!4m10!1m2!2m1!1smamledar+misal++Vijay+Laxmi+Complex,+Old+bhiwandi+road,+Kasheli,+ThanePin+code+:+421302!3m6!1s0x3be7bb0f97dce2d7:0xc30ec89e822b7a81!8m2!3d19.2390776!4d73.0164161!15sCldtYW1sZWRhciBtaXNhbCAgVmlqYXkgTGF4bWkgQ29tcGxleCwgT2xkIGJoaXdhbmRpIHJvYWQsIEthc2hlbGksIFRoYW5lUGluIGNvZGUgOiA0MjEzMDIiA4gBAZIBCnJlc3RhdXJhbnTgAQA!16s%2Fg%2F11p5j8y2dc"
    },
    {
      "Location": "Kandivali",
      "Map": "https://www.google.com/maps/place/Mamledar+Misal/@19.213018,72.8393079,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7b6d18d905c3b:0x8c3ab035d2c3ed2f!8m2!3d19.213018!4d72.8414966!16s%2Fg%2F11b7lnggdq?hl=en"
    },
    {
      "Location": "Kopar Khairane",
      "Map": "https://www.google.com/maps/place/Mamledar+Misal/@19.1011226,73.001901,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7c105c2c2b4f9:0xd92e70515c9701b!8m2!3d19.1011226!4d73.0040897!16s%2Fg%2F11f_p2j42w"
    },
    {
      "Location": "Vasant Vihar",
      "Map": "https://www.google.com/maps/dir//Shop+15,+Phase+2,+opp.+Lok+Upavan,+Vasant+Vihar,+Thane+West,+Thane,+Maharashtra+400610/@19.2212141,72.9002393,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3be7b90a2ea4074d:0x752e38e1cc9d94ac!2m2!1d72.9702798!2d19.2212274"
    },
    {
      "Location": "Ulwe",
      "Map": "https://www.google.com/maps/place/Mamledar+Misal/@18.9924985,73.0348881,13z/data=!3m1!5s0x3be7e849cae97ad7:0x3f7553dcb0caa82!4m10!1m2!2m1!1smamledar+misal++Ulwe,+Navi+Mumbai+410206!3m6!1s0x3be7e9d9f3d55f95:0xa9d5052a8092048b!8m2!3d19.0082439!4d73.109366!15sCihtYW1sZWRhciBtaXNhbCAgVWx3ZSwgTmF2aSBNdW1iYWkgNDEwMjA2WigiJm1hbWxlZGFyIG1pc2FsIHVsd2UgbmF2aSBtdW1iYWkgNDEwMjA2kgEKcmVzdGF1cmFudOABAA!16s%2Fg%2F11q8y60jnz"
    },
    {
      "Location": "Badalapur",
      "Map": "https://www.google.com/maps/place/Mamledar+Misal-Badlapur+(W)/@19.1731105,73.2322615,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7931299747fd7:0xa5648e3c11e98872!8m2!3d19.1731105!4d73.2344502!16s%2Fg%2F11q47_xqxz"
    },
    {
      "Location": "Nerul",
      "Map": "https://www.google.com/maps?q=mamledar+misal+nerul&rlz=1C5CHFA_enIN974IN974&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjOtpan1Kb-AhWM4jgGHaqmAagQ_AUoAXoECAIQAw"
    },
    {
      "Location": "Andheri",
      "Map": "https://www.google.com/maps/dir/19.0283776,72.843264/19.1212283,72.8600828/@19.0708194,72.8155653,13z/data=!3m1!4b1!4m4!4m3!1m1!4e1!1m0"
    },
    {
      "Location": "Ghodbandar Road",
      "Map": "https://www.google.com/maps/dir/19.0283776,72.843264/19.2640395,72.9650451/@19.2409418,72.9655471,13.89z/data=!4m4!4m3!1m1!4e1!1m0"
    },
    {
      "Location": "Mira Road JP North",
      "Map": "https://www.google.com/maps/place/Mamledar+Misal,+Mira+Road/@19.2830503,72.885599,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7b10dbc94e409:0x732d7b9437e56cf4!8m2!3d19.2830503!4d72.8877877!16s%2Fg%2F11sdd4nc4d"
    },
    {
      "Location": "Mira Road",
      "Map": "https://www.google.com/maps/place/Mamledar+Misal/@19.2939959,72.8526346,15.1z/data=!4m10!1m2!2m1!1smamledar+misal+Shop+No+22+bldg+4+NG+shelter+Cinemax+road+mira+road+401105!3m6!1s0x3be7b1606d3f3695:0x6e55345775f497a0!8m2!3d19.293901!4d72.8707449!15sCkltYW1sZWRhciBtaXNhbCBTaG9wIE5vIDIyIGJsZGcgNCBORyBzaGVsdGVyIENpbmVtYXggcm9hZCBtaXJhIHJvYWQgNDAxMTA1kgEFaG90ZWzgAQA!16s%2Fg%2F11sc_nlhjy"
    },
    {
      "Location": "Thane Kacheri",
      "Map": "https://g.co/kgs/sZb2zb"
    },
    {
      "Location": "Zudio Kothrud - Pune",
      "Map": "https://goo.gl/maps/Wrim4nVReeM5MbyF9"
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
